const config = {}

export async function initConfig () {
  const [json, common] = await Promise.all([
    fetch('/conf/as2-portal.json').then(response => response.json()),
    fetch('/conf/common.json').then(response => response.json())
  ])
  Object.assign(config, common, json)
  Object.freeze(config)
}

export default config
