import '@/registerServiceWorker'
import { initConfig } from './config'

(async function createApp () {
  try {
    /*
      It is very important to fetch the configuration before importing any JS
      This is why all the services used in this function are imported dynamically
    * */
    await initConfig()
    await import('@/firebase')
    const { default: authService } = await import('@/auth')
    await authService.authenticate()
    await import('./create-app')
  } catch (error) {
    console.error(error)
  }
})()
